import { SubtitleTypeFontE, TitleTypeFontE } from '@/types/cyclone/models';

export {
  formatDay,
  getRefundTime,
  getDayWithTime,
  getTime,
  getDatesInterval,
  getDayWeekName,
  getMondayCurrentWeek,
  getFormatDuration
} from './schedule';

export { getSubscriptionRecurrence, getDaysFromWorkingHours } from './recurrence';

export const isClickInside = (
  ref: React.MutableRefObject<HTMLDivElement | null>,
  target: EventTarget
): boolean => ref.current?.contains(target as Node) || false;

export const getBase64 = (file: File): Promise<unknown> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const omit = (obj: any, ...keys: any) => {
  const keysToRemove = new Set(keys.flat()); // flatten the props, and convert to a Setx

  return Object.fromEntries(
    // convert the entries back to object
    Object.entries(obj) // convert the object to entries
      .filter(([k]) => !keysToRemove.has(k)) // remove entries with keys that exist in the Set
  );
};

export const removeHTMLTags = (text: string) => {
  const regex = /(<([^>]+)>)/gi;
  const cleanString = text.replace(regex, '');
  return cleanString;
};

export const flatten = (arr: any[]): any[] =>
  arr.reduce((flat, toFlatten) => flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten), []);

export const processDraftEditorDescription = (description?: string) => {
  const processedDescription = description ? description.replace(/<p>/g, '').replace(/<\/p>/g, '<br/>') : '';
  return processedDescription;
};

export const configFontStyle = (fontFamily: TitleTypeFontE | SubtitleTypeFontE): string => {
  switch (fontFamily) {
    case TitleTypeFontE.BANGERS:
      return 'font-bangers font-medium tracking-wide mt-1 text-lg md:text-xl lg:text-2xl subpixel-antialiased';
    case TitleTypeFontE.ZEN:
      return 'font-zen font-bold tracking-normal text-sm md:text-lg lg:text-xl subpixel-antialiased';
    case TitleTypeFontE.PLAYFAIR:
      return 'font-playfair font-bold text-sm tracking-wide md:text-lg lg:text-2xl subpixel-antialiased';
    case TitleTypeFontE.PLAYFAIR_SC:
      return 'font-playfairSc font-bold text-sm tracking-wide md:text-lg lg:text-2xl subpixel-antialiased';
    case TitleTypeFontE.POPPINS:
      return 'font-poppins font-medium leading-tight text-base md:text-lg lg:text-xl subpixel-antialiased';
    case TitleTypeFontE.RALEWAY:
      return 'font-raleway font-bold tracking-wide text-lg md:text-xl lg:text-2xl subpixel-antialiased';
    case TitleTypeFontE.ABHAYA_EXTRABOLD:
      return 'font-abhayaExtraBold font-extrabold tracking-wide text-lg md:text-xl lg:text-2xl subpixel-antialiased';
    case TitleTypeFontE.CINZEL_DECORATIVE:
      return 'font-cinzelDecorative font-normal tracking-wide text-lg md:text-xl lg:text-2xl subpixel-antialiased';
    case TitleTypeFontE.KAPAKANA:
      return 'font-kapakana font-normal tracking-wide text-lg md:text-xl lg:text-2xl subpixel-antialiased';
    case TitleTypeFontE.MERIENDA:
      return 'font-merienda font-normal tracking-wide text-lg md:text-xl lg:text-2xl subpixel-antialiased';
    case TitleTypeFontE.MERRIWEATHER:
      return 'font-merriweather font-bold tracking-wide text-lg md:text-xl lg:text-2xl subpixel-antialiased';
    case TitleTypeFontE.QUATTROCENTO:
      return 'font-quattrocento font-bold tracking-wide text-lg md:text-xl lg:text-2xl subpixel-antialiased';
    case TitleTypeFontE.MONTSERRAT:
      return 'font-montserrat font-bold tracking-wide text-lg md:text-xl lg:text-2xl subpixel-antialiased';
    default:
      return 'text-lg md:text-xl lg:text-2xl font-medium';
  }
};

export function normalizeText(input: string): string {
  return input.replace(/\s*(\/|-)\s*/g, ' $1 ');
}

export function formatLongTextWithSpaces(text: string) {
  // Función para agregar espacios alrededor de caracteres especiales
  function addSpacesAroundSpecialChars(text: string) {
    const specialChars = ['-', '+', '/'];
    const regex = new RegExp(`([${specialChars.join('')}])`, 'g');
    return text.replace(regex, ' $1 ');
  }

  // Función para detectar y dividir el texto largo sin espacios
  function splitLongText(text: string, maxLength: number) {
    const words = text.split(' ');
    let result = '';
    let currentLineLength = 0;

    for (let i = 0; i < words.length; i++) {
      const word = words[i];

      if (currentLineLength + word.length > maxLength) {
        result += '\n';
        currentLineLength = 0;
      }

      result += word + ' ';
      currentLineLength += word.length + 1;
    }

    return result.trim();
  }

  // Aplica las dos funciones al texto
  const textWithSpaces = addSpacesAroundSpecialChars(text);
  const formattedText = splitLongText(textWithSpaces, 8);

  return formattedText;
}
